module.exports = [{
      plugin: require('/Users/gerswin/Proyectos/vzlaAidEs/node_modules/gatsby-plugin-heap/gatsby-browser.js'),
      options: {"plugins":[],"appId":"1905277616","enableOnDevMode":true},
    },{
      plugin: require('/Users/gerswin/Proyectos/vzlaAidEs/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-134540830-1","head":false,"anonymize":true,"respectDNT":true,"optimizeId":"GTM-5W3RTG2","cookieDomain":"venezuelaaidlive.com"},
    },{
      plugin: require('/Users/gerswin/Proyectos/vzlaAidEs/node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"548219862364475"},
    }]
