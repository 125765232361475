// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/gerswin/Proyectos/vzlaAidEs/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-en-js": () => import("/Users/gerswin/Proyectos/vzlaAidEs/src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("/Users/gerswin/Proyectos/vzlaAidEs/src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-page-2-en-js": () => import("/Users/gerswin/Proyectos/vzlaAidEs/src/pages/page-2.en.js" /* webpackChunkName: "component---src-pages-page-2-en-js" */),
  "component---src-pages-page-2-es-js": () => import("/Users/gerswin/Proyectos/vzlaAidEs/src/pages/page-2.es.js" /* webpackChunkName: "component---src-pages-page-2-es-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/gerswin/Proyectos/vzlaAidEs/.cache/data.json")

